<template>
  <div>
    <InputLabel v-if="label" :id="name" :required="required" class="mb-1">
      {{ label }}
    </InputLabel>
    <BareBoneFileUploader
      v-slot="{ openUploader, clearFiles }"
      class="flex items-center justify-between p-4 transition bg-white border border-dashed border-gray-300 rounded hover:border-primary"
      dragging-classes="border-primary"
      :accept="accept"
      :name="name"
      @upload="upload"
    >
      <div
        v-if="!working"
        class="flex items-center justify-between w-full space-x-4 cursor-pointer"
      >
        <div>
          <p class="text-gray-500 text-sm font-medium">
            {{ uploadedFile ? uploadedFile.name : $t("orders.drop_file_or_click") }}
          </p>
          <p v-if="!uploadedFile && extraInfo" class="text-gray-400 text-xs font-medium">
            {{ extraInfo }}
          </p>
        </div>
        <TertiaryButton
          v-if="uploadedFile"
          square
          @click.stop="
            () => {
              clearFiles()
              clear()
            }
          "
        >
          <TrashIcon class="w-4 h-4" />
        </TertiaryButton>
        <TertiaryButton v-else compact @click.stop="openUploader">{{
          $t("forms.file_upload.btn")
        }}</TertiaryButton>
      </div>
      <div v-else class="flex items-center h-full">
        <p class="flex items-center space-x-2 tracking-widest uppercase text-2xs">
          <LoadingIcon class="h-4 w-5" />
          <span v-html="$t('orders.reading_excel')"></span>
        </p>
      </div>
    </BareBoneFileUploader>
    <ErrorMessage v-if="error" class="mt-1">{{ error }}</ErrorMessage>
  </div>
</template>

<script setup>
import { ref } from "vue"
import InputLabel from "@/components/elements/inputs/_base/InputLabel.vue"
import BareBoneFileUploader from "./BareBoneFileUploader.vue"
import TertiaryButton from "../buttons/TertiaryButton.vue"
import { LoadingIcon, TrashIcon } from "../../icons"
import ErrorMessage from "@/components/elements/inputs/_base/ErrorMessage.vue"
const emit = defineEmits(["input"])
const props = defineProps({
  working: Boolean,
  accept: {
    type: String,
    default: "*",
  },
  name: String,
  extraInfo: String,
  file: Object,
  label: String,
  required: Boolean,
  error: {
    type: [String, Boolean],
    default: false,
  },
})
const uploadedFile = ref(props.file)
const upload = value => {
  uploadedFile.value = value
  emit("input", value)
}
const clear = () => {
  uploadedFile.value = null
  emit("input", null)
}
</script>
